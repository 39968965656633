import { acceptHMRUpdate, defineStore } from 'pinia'

const state = () => ({
  history: [] as string[],
})

const MAX_ITEMS_IN_HISTORY = 4

export type RoutingState = ReturnType<typeof state>

export const useRoutingStore = defineStore('routing', {
  state: (): RoutingState => ({ ...state() }),
  actions: {
    SET_PREVIOUS(newRoute: string) {
      this.history = [...this.history, newRoute]
      this.history.length > MAX_ITEMS_IN_HISTORY && this.history.shift()
    },
  },
  getters: {
    PREVIOUS(state) {
      return state.history[state.history.length - 1] || ''
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useRoutingStore, import.meta.hot))
